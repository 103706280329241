.site-configuration-modal {
  max-width: 90vw;
  min-width: 70vw;
}

.site-configuration-modal .ant-modal-content {
  min-height: 80vh;
}

.list-view .list-item {
  cursor: pointer;
  margin-top: 2px;
  transition: background-color 0.3s, color 0.3s;
}

.list-view .list-item:hover {
  background-color: var(--ant-color-primary-hover);
  color: var(--ant-color-white);
}

.list-view .list-item-selected {
  margin-top: 2px;
  background-color: var(--ant-color-primary-active);
  color: var(--ant-color-white);
}
