.system-card {
  --smallgap: 10px;
  --biggap: 50px;
  padding-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  height: 100%;
}

.system-col {
  width: auto;
  height: 100%;
  display: grid;
  grid-template-columns: 1;

  gap: var(--smallgap);
}

.system-row {
  display: flex;
  gap: var(--biggap);
  flex-wrap: wrap;

  height: fit-content;
}

.content-box {
  flex: 1 1 30%;

  min-width: 320px;
  min-height: none;
  height: fit-content;
  padding: 0px 0px 0px 0px;

  display: flex;
  flex-direction: column;
  gap: var(--smallgap);
}
