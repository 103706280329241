/*
    CONTAINER STYLES
*/
.dashboard-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  height: 98%;
  width: 100%;

  overflow-x: hidden;

  --gap: 0.2%;
}

/* @media (min-width: 1200px) and (min-height: 1137px) {
  .dashboard-container {
    overflow-y: hidden;
  }
} */

.weather-sysinfo-summary {
  display: flex;
  flex-direction: column;

  flex: 1 0 250px; /* width */
  gap: var(--gap);
  /* border:5px solid rgb(177, 236, 236); */

  height: auto;
}

.all-graphs {
  display: flex;
  flex-direction: column;
  flex: 1 1 70%;
  min-width: 50%;
  /* border: 5px solid yellow; */
  gap: var(--gap);
}

.statistic-graphs {
  /*don't fix height
      height determine by content
    */
  display: grid;

  @media (min-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  width: auto;

  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));

  /* border: 5px solid green; */
  gap: var(--gap);
}

.other-graphs {
  /* basis: 80% height, grow to fit the remaining height */
  flex: 0 1 80%;
  width: inherit;

  display: flex;
  flex-direction: row;
  /* overflow-x: scroll;
    overflow-y: hidden; */

  @media (max-width: 800px) {
    flex-wrap: wrap;
    max-height: 800px;
  }

  /* border: 5px solid red; */
  gap: var(--gap);
}

.power-energy {
  flex: 1 0;

  height: 100%;
  width: 50%;

  @media (width < 576px) {
    max-height: 600px;
  }

  gap: var(--gap);
}

.condition-heatmap {
  flex: 1 0;

  height: 100%;
  width: 50%;

  @media (width < 576px) {
    max-height: 100%;
  }

  display: flex;
  flex-direction: column;

  /* border: 5px solid orange; */
  gap: var(--gap);
}

/* CAROUSEL STYLES */

/* carousel navigation button */
.bgPrimary > li > button {
  background-color: blue !important;
}

/*
    CARD STYLES
*/

.dashboard-container .ant-card-body {
  /*
    remove the padding of all cards in dashboard
    so that content fit the entire card
    */
  padding: 0;
}

.weather-card {
  width: auto;
  flex: 1 1 10%;
  min-height: 110px;
  max-height: 120px;

  overflow: hidden;
}

.sysinfo-card {
  width: inherit;
  /* flex: 1 1 35%; */
  min-height: 200px;
}

.summary-card {
  width: 100%;
  min-height: 200px; /*FOR MOBILE*/
}

.statistic-graph-card {
  min-width: 25%;
}

.power-energy .ant-card-body {
  height: 95%;
  @media (width < 576px) {
    height: 85%;
  }
}

.condition-alarm-card {
  flex: 0 0;
  height: fit-content;
}

.heatmap-card {
  flex: 1 1;
}

.heatmap-card .ant-card-body {
  height: 90%;
}

.soc-chart-card {
  flex: 1 1;
}

.soc-chart-card .ant-card-body {
  height: 90%;
}

/*
    Other Styles
*/

.condition-alarm-card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.condition-alarm-card-title-span {
  font-size: 0.7rem;
  font-weight: 500;
}

.dashboard-empty {
  position: absolute;
  top: 30%;
  left: 13%;
}
