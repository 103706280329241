.st-report-card {
  min-height: calc(100vh - 150px);
}

.st-report-card-content {
  display: flex;
  flex-direction: column;

  min-width: 300px;
}

.st-report-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  flex-wrap: wrap;
  padding: 10px 0;
}

.st-report-row {
  display: grid;

  grid-template-columns: 1fr 1fr;

  padding: 10px;
  border: 1px solid rgba(191, 186, 186, 0.2);
  border-bottom: none;
}

.st-report-last-row {
  border-bottom: 1px solid rgba(191, 186, 186, 0.2);
}

.st-report-row-title {
  font-weight: 500;
}

.st-report-col-1,
.st-report-col-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}

.st-report-divider {
  height: 16px;
  background-color: rgba(191, 186, 186, 0.2);
}

.align-center {
  align-items: center;
}

.st-report-reset-button-container {
  display: flex;
  justify-content: right;
}

.st-report-submit-button {
  width: 200px;
  margin: 10px 5px 10px auto;
}

.st-report-reset-button {
  width: 200px;
  margin: 10px 0 10px auto;
}
