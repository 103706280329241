:root {
  --gap-size: 20px;
  --gap-button: 5px;
}

.data-reference-content {
  display: flex;
  padding: 10px;
  gap: var(--gap-size);
}

.data-reference-image-triangle {
  position: relative;
  width: 300px;
  height: 200px;
  margin: auto;
}

.data-reference-image-top {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.data-reference-image-left {
  position: absolute;
  bottom: 0;
  left: 0;
}

.data-reference-image-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.left-content {
  width: 40%;
  align-self: center;
}

.right-content {
  width: 60%;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  gap: var(--gap-size);
  box-sizing: border-box;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.label-style {
  font-size: 16px;
  font-weight: 500;
}

@media (max-width: 1200px) {
  .data-reference-content {
    flex-wrap: wrap;
  }

  .button-container {
    gap: var(--gap-button);
  }

  .left-content {
    width: 100%;
  }

  .right-content {
    width: 100%;
  }
}
