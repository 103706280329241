.heatmap-content {
  padding: 10px;
  height: 100%;
  overflow: hidden;
}

.heatmap-card {
  flex: 1 1;
}

.heatmap-card .ant-card-body {
  height: 90%;
}
