.pvcondition-alarm-content-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: auto;
  height: fit-content;
  /* overflow: scroll; */

  justify-content: center;

  margin: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.pvcondition-alarm-content-container span {
  padding: 5px;
  /* text-align: center; */
  font-weight: 600;
  font-size: large;
}

.alarm-card .ant-card-body {
  padding: 0x !important;
}

.pv-condition-card .ant-card-body {
  padding: 0px !important;
}
