.h-100 {
  height: 100% !important;
}

.h-75 {
  height: 75% !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-1 {
  margin-top: 4px !important;
}

.mt-2 {
  margin-top: 8px !important;
}

.mt-3 {
  margin-top: 12px !important;
}

.mt-4 {
  margin-top: 16px !important;
}

.mt-5 {
  margin-top: 20px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-1 {
  margin-bottom: 4px !important;
}

.mb-2 {
  margin-bottom: 8px !important;
}

.mb-3 {
  margin-bottom: 12px !important;
}

.mb-4 {
  margin-bottom: 16px !important;
}

.mb-5 {
  margin-bottom: 20px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-1 {
  margin-left: 4px !important;
}

.ml-2 {
  margin-left: 8px !important;
}

.ml-3 {
  margin-left: 12px !important;
}

.ml-4 {
  margin-left: 16px !important;
}

.ml-5 {
  margin-left: 20px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-1 {
  margin-right: 4px !important;
}

.mr-2 {
  margin-right: 8px !important;
}

.mr-3 {
  margin-right: 12px !important;
}

.mr-4 {
  margin-right: 16px !important;
}

.mr-5 {
  margin-right: 20px !important;
}

.m-0 {
  margin: 0px !important;
}

.m-1 {
  margin: 4px !important;
}

.m-2 {
  margin: 8px !important;
}

.m-3 {
  margin: 12px !important;
}

.m-4 {
  margin: 16px !important;
}

.m-5 {
  margin: 20px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-1 {
  padding-top: 4px !important;
}

.pt-2 {
  padding-top: 8px !important;
}

.pt-3 {
  padding-top: 12px !important;
}

.pt-4 {
  padding-top: 16px !important;
}

.pt-5 {
  padding-top: 20px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-1 {
  padding-bottom: 4px !important;
}

.pb-2 {
  padding-bottom: 8px !important;
}

.pb-3 {
  padding-bottom: 12px !important;
}

.pb-4 {
  padding-bottom: 16px !important;
}

.pb-5 {
  padding-bottom: 20px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-1 {
  padding-left: 4px !important;
}

.pl-2 {
  padding-left: 8px !important;
}

.pl-3 {
  padding-left: 12px !important;
}

.pl-4 {
  padding-left: 16px !important;
}

.pl-5 {
  padding-left: 20px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pr-1 {
  padding-right: 4px !important;
}

.pr-2 {
  padding-right: 8px !important;
}

.pr-3 {
  padding-right: 12px !important;
}

.pr-4 {
  padding-right: 16px !important;
}

.pr-5 {
  padding-right: 20px !important;
}

.p-0 {
  padding: 0px !important;
}

.p-1 {
  padding: 4px !important;
}

.p-2 {
  padding: 8px !important;
}

.p-3 {
  padding: 12px !important;
}

.p-4 {
  padding: 16px !important;
}

.p-5 {
  padding: 20px !important;
}

.bgPrimary > li > button {
  background-color: blue !important;
}

@keyframes blink-danger {
  0% {
    box-shadow: 0 0 8px red;
  }
  50% {
    box-shadow: none;
  }
  100% {
    box-shadow: 0 0 8px red;
  }
}

@-webkit-keyframes blink-danger {
  0% {
    box-shadow: 0 0 8px red;
  }
  50% {
    box-shadow: 0 0 0;
  }
  100% {
    box-shadow: 0 0 8px red;
  }
}

@keyframes blink-warning {
  0% {
    box-shadow: 0 0 8px orange;
  }
  50% {
    box-shadow: none;
  }
  100% {
    box-shadow: 0 0 8px orange;
  }
}

@-webkit-keyframes blink-warning {
  0% {
    box-shadow: 0 0 8px orange;
  }
  50% {
    box-shadow: 0 0 0;
  }
  100% {
    box-shadow: 0 0 8px orange;
  }
}

.device-card-success {
  box-shadow: 0px 0px 5px green;
}

.device-card-danger {
  -webkit-animation: blink-danger 1.5s linear infinite;
  -moz-animation: blink-danger 1.5s linear infinite;
  -ms-animation: blink-danger 1.5s linear infinite;
  -o-animation: blink-danger 1.5s linear infinite;
  animation: blink-danger 1.5s linear infinite;
}

.device-card-warning {
  -webkit-animation: blink-warning 1.5s linear infinite;
  -moz-animation: blink-warning 1.5s linear infinite;
  -ms-animation: blink-warning 1.5s linear infinite;
  -o-animation: blink-warning 1.5s linear infinite;
  animation: blink-warning 1.5s linear infinite;
}

.device-card-disabled {
  box-shadow: 0px 0px 5px 0px gray;
}
