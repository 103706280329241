.analytics-filter-card,
.analytics-content-card {
  height: calc(100vh - 130px);
}

.analytics-content-card .ant-card-body {
  height: calc(100% - 48px);
}

.analytics-content-card-title {
  display: flex;
  justify-content: space-between;
}

.analytics-picker-type-segmented .ant-segmented-item {
  width: 100%;
}
